import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useField } from 'formik';
import VisibilityOffOutlined from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';

const PasswordField = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [field, meta] = useField('password');

  const toggleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <TextField
      {...field}
      name="password"
      type={showPassword ? 'text' : 'password'}
      label="Contraseña"
      variant="filled"
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ' '}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggleShowPassword}
              sx={{
                color: 'primary.main',
              }}
              edge="end"
            >
              {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
