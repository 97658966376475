import React, { useMemo } from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';
import { prettifyRut } from 'react-rut-formatter';
import { useTranslation } from 'react-i18next';
import useGetCountryFromUrl from '../../hooks/useGetCountryFromUrl';
import useSelectedCompany from '../../hooks/useSelectedCompany';

const FORMATTERS = {
  Chile: (rut) => (rut !== '0' || '' ? prettifyRut(rut) : ''),
  Mexico: (rfc) => rfc?.toUpperCase().replace(/ /g, '') || '',
};

const NationalIdentifierField = ({ ...props }) => {
  const selectedCompany = useSelectedCompany();
  const countryFromUrl = useGetCountryFromUrl();
  const { t } = useTranslation();
  const [field, meta, { setValue }] = useField('nationalIdentifier');

  const country = useMemo(
    () => selectedCompany?.masterEntity?.country || countryFromUrl,
    [selectedCompany, countryFromUrl],
  );

  const handleChange = (event) => {
    const formatter = FORMATTERS[country.name];
    const formattedValue = formatter(event.target.value);
    setValue(formattedValue);
  };

  return (
    <TextField
      {...field}
      label={t('National identifier')}
      variant="outlined"
      fullWidth
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error ? meta.error : ' '}
      {...props}
      onChange={handleChange}
    />
  );
};

export default NationalIdentifierField;
