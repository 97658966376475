import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { DicomDetailsColumns } from '../../constants';
import { formatMoney } from '../../helpers';
import FingoDataGrid from '../dataGrids/FingoDataGrid';

const DicomBulletins = ({ dicomModel }) => (
  <Stack
    direction="column"
    spacing={4}
    sx={{ marginTop: 5 }}
  >
    <Stack direction="row" spacing={20} justifyContent="center" divider={<Divider orientation="vertical" flexItem />}>
      <Stack direction="column" spacing={0.5} alignItems="center">
        <Typography><b>Monto Total Impagos</b></Typography>
        <Typography>${formatMoney(dicomModel?.dicomAmount)}</Typography>
      </Stack>
      <Stack direction="column" spacing={0.5} alignItems="center">
        <Typography><b>Total Documentos impagos</b></Typography>
        <Typography>{dicomModel?.dicomBulletins.length}</Typography>
      </Stack>
    </Stack>
    <FingoDataGrid
      rows={dicomModel?.dicomBulletins ?? []}
      columns={DicomDetailsColumns}
      serverFilters={false}
      sx={{ minHeight: 250 }}
      hideFooter
    />
  </Stack>
);

DicomBulletins.propTypes = {
  dicomModel: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    dicomAmount: PropTypes.number,
    dicomBulletins: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      coinType: PropTypes.string,
      amount: PropTypes.string,
      market: PropTypes.string,
      drawer: PropTypes.string,
      debtType: PropTypes.string,
      doctoNumber: PropTypes.string,
      documentType: PropTypes.string,
      expirationDate: PropTypes.string,
      doctoEntryDate: PropTypes.string,
    })),
  }),
};

DicomBulletins.defaultProps = {
  dicomModel: {},
};

export default DicomBulletins;
