import React from 'react';
import Typography from '@mui/material/Typography';
import propTypes from 'prop-types';

const DicomAlert = ({ selectedDicom }) => (
  (selectedDicom && !selectedDicom.completedAt) && (
  <Typography
    variant="h6"
    sx={{
      textAlign: 'center',
      color: 'error.A700',
      mt: 2,
    }}
  >
    No se pudo procesar Dicom
  </Typography>
  )
);

export default DicomAlert;

DicomAlert.propTypes = {
  selectedDicom: propTypes.shape({
    completedAt: propTypes.string,
  }),
};

DicomAlert.defaultProps = {
  selectedDicom: null,
};
